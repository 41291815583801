import {
  BenefitsLeftSide,
  ScaleButton
} from "@/StyledComponents/TeamStyled";
import { Icon } from "@iconify/react";
import { gsap } from "gsap";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Container, List } from "reactstrap";
import SMEArianeBackgroundIconSvg from "../svg/SoftwareSME/SMEArianeBackgroundIconSvg";

export default function JobVideoSection() {
  const arrowRef = useRef(null);
  const videoRef = useRef(null);
  const [posterLoaded, setPosterLoaded] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);

  useEffect(() => {
    gsap.to(arrowRef.current, {
      x: 10,
      duration: 0.7,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
    });
  }, []);

  useEffect(() => {
    const enableLazyLoad = () => setPosterLoaded(true);
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setVideoVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.5 }
    );

    if (videoRef.current) observer.observe(videoRef.current);

    window.addEventListener("mousemove", enableLazyLoad);
    window.addEventListener("click", enableLazyLoad);
    window.addEventListener("scroll", enableLazyLoad);

    return () => {
      window.removeEventListener("mousemove", enableLazyLoad);
      window.removeEventListener("click", enableLazyLoad);
      window.removeEventListener("scroll", enableLazyLoad);
      observer.disconnect();
    };
  }, []);

  return (
    <Container className="p-b-section !mt-[-50px]">
      <div className="w-full sticky">
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true} className="sticky top-[80px]">
          <div className="w-full h-full relative">
            <SMEArianeBackgroundIconSvg />
            <div className="relative md:mt-[6rem] mt-[3rem] lg:pb-0  rounded-[1rem] md:pb-[2rem] pb-[0rem] w-full flex flex-col md:flex-row lg:flex-row items-start justify-between AIDiscover !pt-0">
              <div className="w-full flex flex-col items-start justify-center rounded-[12px] py-[1rem] md:py-[1.5rem] px-[1rem] md:px-[1.5rem] lg:py-[3rem] lg:px-[3rem]">
                <h3 className="md:text-[16px] xl:text-[26px] lg:text-[26px] font-semibold lg:pb-5 pb-[1.5rem] htitle">
                  Diana Susan is the Senior Partner <br /> and Global Head of Strategy at Afyajobs.
                </h3>
                <p className="md:text-[16px] xl:text-[16px] lg:text-[16px] font-light lg:pb-5 pb-[1.5rem] htitle">
                  She focuses on driving strategic innovation to enhance operational efficiency and deliver measurable impact across evolving industries.
                </p>
                <List className="p-0">
                  {["Business and technical consultancy", "Web app development", "Quality assurance", "UX/UI design"].map((item, index) => (
                    <li key={index} className="flex items-baseline flex-row gap-[15px] md:text-[16px] font-light pb-[0.5rem] htitle">
                      <div>— {item}</div>
                    </li>
                  ))}
                </List>
                <div className="text-[12px] leading-[12px] tracking-[2px] text-[#1D1E22] uppercase font-light pb-[0px] lg:pb-[1rem] pt-[1.5rem] w-full flex items-center md:justify-start justify-center htitle">
                  <Link href="/team-extension" target="_self">
                    <ScaleButton className="red-app-btn !my-[0]">
                      Extend my team <span ref={arrowRef}><Icon icon="tabler:arrow-right" /></span>
                    </ScaleButton>
                  </Link>
                </div>
              </div>

              {/* Right Side (Video Section) */}
              <div className="w-full flex flex-col items-start justify-center rounded-[12px] py-[1rem] md:py-[1.5rem] px-[1rem] md:px-[1.5rem] lg:py-[3rem] lg:px-[3rem]">
                <BenefitsLeftSide className="video_section !w-full !pr-0">
                  <video
                    ref={videoRef}
                    className="w-full rounded-[16px] h-full object-contain cursor-pointer"
                    poster={posterLoaded ? "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Diana-Susan.webp" : ""}
                    controls
                    loop
                    muted
                    playsInline
                    preload="none"
                    autoPlay={videoVisible}
                  >
                    {videoVisible && (
                      <source
                        src="https://d3nxqgkzw2f14c.cloudfront.net/itservicesindia/videos/03_Diana_Susan_Testimonial_Corrected.mp4"
                        type="video/mp4"
                      />
                    )}
                    <track src="/VideoCaption/captions_en.vtt" kind="captions" srcLang="en" label="English Captions" />
                    <track src="/VideoCaption/captions_es.vtt" kind="captions" srcLang="es" label="Spanish Captions" />
                  </video>
                </BenefitsLeftSide>
              </div>
            </div>
          </div>
        </AnimationOnScroll>
      </div>
    </Container>
  );
}
